<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ user.first_name || 'New User' }}</h1>
    <v-card>
      <v-card-text>
        <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
          <v-form autocomplete="off">
            <v-row>
              <v-col cols="12" md="6">
                <ValidationProvider name="First Name" rules="required|min:2|max:255">
                  <v-text-field
                    v-model="userLocal.first_name"
                    autocomplete="off"
                    :disabled="loading"
                    label="First Name"
                    :error-messages="errors"
                    slot-scope="{ errors }"
                    ref="first_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider name="Surname" rules="required|min:2|max:255">
                  <v-text-field
                    v-model="userLocal.surname"
                    autocomplete="off"
                    :disabled="loading"
                    label="Surname"
                    :error-messages="errors"
                    slot-scope="{ errors }"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider name="Email" rules="required|email|min:2|max:255">
                  <v-text-field
                    v-model="userLocal.email"
                    :disabled="loading"
                    label="Email"
                    autocomplete="off"
                    :error-messages="errors"
                    slot-scope="{ errors }"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider name="Password" vid="password" :rules="passwordRules">
                  <v-text-field
                    v-model="userLocal.password"
                    label="Password"
                    autocomplete="new-password"
                    type="password"
                    :disabled="loading"
                    :error-messages="errors"
                    slot-scope="{ errors }"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider name="Password Confirmation" rules="confirmed:password">
                  <v-text-field
                    v-model="userLocal.password_confirmation"
                    label="Password Confirmation"
                    type="password"
                    autocomplete="off"
                    :disabled="loading"
                    :error-messages="errors"
                    slot-scope="{ errors }"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider name="Security Group">
                  <v-select
                    v-model="roles"
                    :disabled="loading"
                    :items="lists.roles"
                    item-value="name"
                    item-text="name"
                    :menu-props="{ maxHeight: '400' }"
                    label="Select"
                    multiple
                    hint="Pick security group"
                    persistent-hint
                    :error-messages="errors"
                    slot-scope="{ errors }"
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
                <v-btn @click="remove2faAction" v-if="user.has_2fa" :disabled="loading" class="ml-4">
                  <v-icon left dark color="warning">mdi-alert</v-icon>
                  Reset 2fa</v-btn
                >
              </v-col></v-row
            >
          </v-form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min, email, confirmed } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';

extend('required', required);
extend('confirmed', confirmed);
extend('max', max);
extend('min', min);
extend('email', email);

export default {
  name: 'UserView',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('user/get', to.params.id).then(() => {
      next();
    });
  },
  methods: {
    /**
     * Check if this a new record
     */
    isNewRecord() {
      return this.$route.params.id == 'new' ? true : false;
    },
    /**
     * Set the focus on the first field if possible
     */
    focusFirstField() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.first_name.focus();
        }, 100);
      });
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        store.dispatch('user/save', {
          id: this.userLocal.id,
          group: this.userLocal
        });
      }
      this.resetValidation();
    },
    /**
     * Reset two factor authentication for user
     */
    async remove2faAction() {
      await store.dispatch('user/remove2fa', this.userLocal.id);
      await store.dispatch('user/get', this.userLocal.id);
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    },
    /**
     * Delete action for the form
     */
    deleteAction() {},
    /**
     * Check if the form has changed after loading
     */
    isDirty() {
      return this.$refs.objects._data.flags.dirty;
    }
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('user', ['loading', 'error', 'user', 'meta']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        securityGroups: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save. This is where we
     * set the default values for new records
     */
    userLocal: function () {
      const userLocal = loadDash.cloneDeep(this.user);
      this.focusFirstField();
      return userLocal;
    },
    roles: {
      get: function () {
        if (this.userLocal && this.userLocal.authorization) {
          return this.userLocal.authorization.roles;
        }
        return [];
      },
      set: function (ids) {
        this.userLocal.authorization.roles = ids;
      }
    },
    /**
     * Get dynamic password rules based if user is a new user or not
     */
    passwordRules: function () {
      let rules = ['min:10', 'max:20'];
      if (this.isNewRecord()) {
        rules.push('required');
      }
      return rules.join('|');
    }
  },
  data: () => ({
    password: '',
    showPassword: false,
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Users',
        exact: true,
        to: '/users'
      },
      {
        text: 'User',
        disabled: true,
        exact: true,
        to: '/users/:id'
      }
    ]
  })
};
</script>
