import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('h1',{staticClass:"headline pb-4 mx-lg-auto"},[_vm._v(_vm._s(_vm.user.first_name || 'New User'))]),_c(VCard,[_c(VCardText,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c(VForm,{attrs:{"autocomplete":"off"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{ref:"first_name",attrs:{"autocomplete":"off","disabled":_vm.loading,"label":"First Name","error-messages":errors},model:{value:(_vm.userLocal.first_name),callback:function ($$v) {_vm.$set(_vm.userLocal, "first_name", $$v)},expression:"userLocal.first_name"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Surname","rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"autocomplete":"off","disabled":_vm.loading,"label":"Surname","error-messages":errors},model:{value:(_vm.userLocal.surname),callback:function ($$v) {_vm.$set(_vm.userLocal, "surname", $$v)},expression:"userLocal.surname"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Email","autocomplete":"off","error-messages":errors},model:{value:(_vm.userLocal.email),callback:function ($$v) {_vm.$set(_vm.userLocal, "email", $$v)},expression:"userLocal.email"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Password","vid":"password","rules":_vm.passwordRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"label":"Password","autocomplete":"new-password","type":"password","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.userLocal.password),callback:function ($$v) {_vm.$set(_vm.userLocal, "password", $$v)},expression:"userLocal.password"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Password Confirmation","rules":"confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"label":"Password Confirmation","type":"password","autocomplete":"off","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.userLocal.password_confirmation),callback:function ($$v) {_vm.$set(_vm.userLocal, "password_confirmation", $$v)},expression:"userLocal.password_confirmation"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Security Group"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.roles,"item-value":"name","item-text":"name","menu-props":{ maxHeight: '400' },"label":"Select","multiple":"","hint":"Pick security group","persistent-hint":"","error-messages":errors},model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}})}}],null,true)})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid || !dirty},on:{"click":_vm.saveAction}},[_vm._v("Save")]),(_vm.user.has_2fa)?_c(VBtn,{staticClass:"ml-4",attrs:{"disabled":_vm.loading},on:{"click":_vm.remove2faAction}},[_c(VIcon,{attrs:{"left":"","dark":"","color":"warning"}},[_vm._v("mdi-alert")]),_vm._v(" Reset 2fa")],1):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }